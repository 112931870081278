import { Grid } from '@mui/material';
import React from 'react';
import styles from './why-join.module.scss';
// Import images directly
import adopterIcon from '../../../../assets/images/adopter.png';
import testerIcon from '../../../../assets/images/tester.png';
import gameCoinsIcon from '../../../../assets/images/gameCoins.png';
import Link from '../../../../assets/images/RefLink.png';
import MoreCoin from '../../../../assets/images/Coins.png';
import AIcoin from '../../../../assets/images/AIcoin.png';
import NFT from '../../../../assets/images/Nfts.png';
import ShapeFuture from '../../../../assets/images/ShapeFuture.png';

// Define a type for the keys of iconMap
type IconMapKeys =
  | 'Early Adopter'
  | 'Beta Tester'
  | 'Game Coins'
  | 'Referral Link'
  | 'More Game Coins'
  | 'QuickCam AI Coin'
  | 'NFT’s'
  | 'Shape the Future';

// Create a mapping of title to icon
const iconMap: Record<IconMapKeys, string> = {
  'Early Adopter': adopterIcon,
  'Beta Tester': testerIcon,
  'Game Coins': gameCoinsIcon,
  'Referral Link': Link,
  'More Game Coins': MoreCoin,
  'QuickCam AI Coin': AIcoin,
  'NFT’s': NFT,
  'Shape the Future': ShapeFuture,
};

interface WhyJoinProps {
  data: {
    icon?: string | null;
    title: string;
    desc: string;
    gradient: string;
  }[]; // Interface for data type
}

const WhyJoin: React.FC<WhyJoinProps> = ({ data }) => {
  return (
    <section className={styles.container} id="features">
      <p
        style={{
          fontSize: '22px',
          fontWeight: '700',
        }}
      >
        Why Join?
      </p>
      <br />
      <Grid container spacing={2}>
        {data.map(item => (
          <Grid item xs={12} sm={6} md={4} key={item.title}>
            <div className={styles.box}>
              <div>
                <div
                  className={styles.FirstBox}
                  style={{ backgroundImage: item.gradient }}
                >
                  {iconMap[item.title as IconMapKeys] && (
                    <img
                      src={iconMap[item.title as IconMapKeys]}
                      alt={item.title}
                    />
                  )}
                </div>
              </div>
              <div>
                <p className={styles.boxTitle}>{item.title}</p>
                {item.desc}
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default WhyJoin;
